<template>
  <v-modal name="company-dialog" @before-open="setCompany" @before-close="resetForm" height="auto" width="540">
    <v-card :loading="isLoading">
      <v-card-title class="headline" primary-title>
        {{ isNewCompany ? 'Cég létrehozása' : 'Cég szerkesztése' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-text-field v-model="company.domain" label="Domain" :rules="[requiredRule]"></v-text-field>
          <v-text-field v-model="company.name" label="Név" :rules="[requiredRule]"></v-text-field>
          <v-card-subtitle>Számlázási adatok</v-card-subtitle>

          <p>
            <b>Számlabefogadó email címe:</b> {{ company.billing.email }}<br />
            <b>Adószám:</b> {{ company.billing.vatNumber }}<br />
            <b>Cím: </b>{{ company.billing.postCode }} {{ company.billing.city }} {{ company.billing.address }}
          </p>

          <v-card-subtitle>Funkciók engedélyezése</v-card-subtitle>
          <v-checkbox v-model="company.features.departments" label="Csapatok" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.goals" label="Célok" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.rewards" label="Jutalmak" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.analytics" label="Analitika" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.bountyBoard" label="Bounty Board" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.userNetwork" label="Kapcsolódások" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.questions" label="Megkérdezések" hide-details></v-checkbox>
          <v-checkbox v-model="company.features.praises" label="Dicsfal" hide-details></v-checkbox>
          <v-card-subtitle class="mt-5">Státusz</v-card-subtitle>
          <v-checkbox v-model="company.suspended" label="Cég felfüggesztése" color="red"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="closeDialog">Bezárás</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click="submit">Mentés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
import { diff } from 'deep-object-diff'

import { CREATE_COMPANY, UPDATE_COMPANY } from '@/store/types'
import formControl from '@/mixins/formControl'

const INITIAL_STATE = {
  name: '',
  billing: {
    city: '',
    postCode: '',
    address: '',
    name: '',
    email: '',
    vatNumber: ''
  },
  features: {
    departments: false,
    goals: false,
    rewards: false,
    rewardSuggestions: false,
    analytics: false,
    questions: false,
    bountyBoard: false,
    userNetwork: false,
    praises: false
  },
  suspended: false
}

export default {
  name: 'CompanyDialog',
  mixins: [formControl],
  data() {
    return {
      company: { ...INITIAL_STATE },
      editCompany: {},
      isLoading: false,
      isNewCompany: null
    }
  },
  methods: {
    setCompany(event) {
      this.isNewCompany = event.params.isNewCompany
      this.company = event.params.company
        ? JSON.parse(JSON.stringify({ ...event.params.company }))
        : { ...INITIAL_STATE }

      if (!this.isNewCompany) {
        this.editCompany = JSON.parse(JSON.stringify({ ...event.params.company }))
      }
    },
    resetForm() {
      this.trunk = { ...INITIAL_STATE }
      this.isNewCompany = null
    },
    closeDialog() {
      this.$modal.hide('company-dialog')
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    },
    async saveCompany() {
      this.toggleLoading()

      try {
        await this.$store.dispatch(CREATE_COMPANY, this.company)
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async updateCompany() {
      this.toggleLoading()

      try {
        const companyDiff = diff(this.editCompany, this.company)
        await this.$store.dispatch(UPDATE_COMPANY, { companyId: this.editCompany.id, payload: companyDiff })
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    submit() {
      this.validate()

      if (!this.isValid) {
        return
      }

      if (this.isNewCompany) {
        this.saveCompany()
      } else {
        this.updateCompany()
      }
    }
  }
}
</script>

<style lang="scss">
.v-card__subtitle {
  padding: 0;
  font-weight: 500;

  .theme--light & {
    color: #111 !important;
  }
}
</style>
